
  import { defineComponent, onMounted, reactive, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import { validateChangePassword } from '@/utils/validate';
  import { sendValidCode, submitPasswordInfo } from '@/api/changePassword';
  import Cookies from 'js-cookie';
  import store from '@/store';
  import { ElMessage } from 'element-plus';
  import { getDomain } from '@/utils/urlHandler';

  export default defineComponent({
    setup() {
      const router = useRouter();
      const state = reactive({
        showLogAgain: false,
        ruleForm: null,
        pwdForm: {
          newPwd: '',
          smsCode: '',
          confirmNewPwd: '',
        },
        submitting: false,
        sendText: '获取验证码',
        sendFlag: false,
        codeLoginForm: {
          phone: '',
          smsValidCode: '',
        },
        rules: {
          newPwd: [
            {
              required: true,
              trigger: 'blur',
              validator: validateChangePassword,
            },
          ],
          smsCode: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
          confirmNewPwd: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule: any, value: string, callback: Function) => {
                if (!state.pwdForm.confirmNewPwd) {
                  callback(new Error('请输入确认密码'));
                }
                if (!(state.pwdForm.newPwd === state.pwdForm.confirmNewPwd)) {
                  callback(new Error('确认密码与初始密码不一致'));
                } else {
                  callback();
                }
              },
            },
          ],
        },
      });

      const domain = getDomain(window.location.href);
      const handleReset = () => {
        const form: any = state.ruleForm;
        form.resetFields();
      };
      const handleSubmit = async () => {
        const form: any = state.ruleForm;
        form.validate(async (valid: any) => {
          if (valid) {
            try {
              state.submitting = true;
              const params = {
                ...state.pwdForm,
              };
              const res: any = await submitPasswordInfo(params);
              if (res.code === 0) {
                store.commit('app/setPermissions', []);
                store.commit('app/setHasLogin', false);
                Cookies.remove('token', { domain });
                Cookies.remove('userName', { domain });
                Cookies.remove('tenantId', { domain });
                Cookies.remove('tenantName', { domain });
                sessionStorage.removeItem('switchId');
                sessionStorage.removeItem('token');
                state.showLogAgain = true;
                handleReset();
                ElMessage({
                  message: '密码修改成功',
                  type: 'success',
                });
              } else {
                throw new Error(res.msg || '修改密码失败');
              }
            } catch (err) {
              ElMessage({
                message: `${err}`,
                type: 'error',
              });
            } finally {
              state.submitting = false;
            }
          }
          return false;
        });
      };
      const handleClose = () => {
        const form: any = state.ruleForm;
        form.resetFields();
        router.back();
      };
      const getSmsValidCode = async () => {
        try {
          const res: any = await sendValidCode();
          if (res.code === 0) {
            ElMessage({
              message: '验证码已发送',
              type: 'success',
            });
          }
          let n = 60;
          if (res.code === 0) {
            state.sendFlag = true;
            const timer = setInterval(() => {
              n -= 1;
              if (n < 0) {
                clearInterval(timer);
                state.sendText = '发送验证码';
                state.sendFlag = false;
                return;
              }
              state.sendText = `重新发送(${n}s)`;
            }, 1000);
          }
        } catch (err) {
          console.log('验证码发送失败', err);
        }
      };
      const logAgain = async () => {
        router.push('/login');
      };
      return {
        ...toRefs(state),
        brand: process.env.VUE_APP_BRAND_URL,
        handleSubmit,
        handleClose,
        getSmsValidCode,
        logAgain,
      };
    },
  });
