import $http from '@/utils/axios/axiosUtils';

export function submitPasswordInfo(params: any) {
  return $http({
    url: `/user/pwd/change`,
    method: 'put',
    data: params,
  });
}

export function sendValidCode() {
  return $http({
    url: `/user/changePwd/sms`,
    method: 'get',
  });
}
